
import {
  apiModelLists,
  apiChangeStatus,
  apiChangeIsable,
  apiCategoryList,
  apiAsyncsid,
  apiModelSort,
} from "@/api/model";
import { Component, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
import { PageMode } from "@/utils/type";
import PopoverInput from "@/components/popover-input.vue";

@Component({
  components: {
    lsPagination,
    ExportData,
    LsDialog,
    PopoverInput,
  },
})
export default class ShopList extends Vue {
  /** S Data **/

  apiModelLists = apiModelLists;

  pager: RequestPaging = new RequestPaging();

  searchObj: any = {
    title: "",
    cate_id: "",
  };

  categoryList: any[] = [];
  /** E Data **/

  /** S Methods **/

  // 获取模型列表
  getShopLists(): void {
    this.pager.request({
      callback: apiModelLists,
      params: {
        ...this.searchObj,
      },
    });
  }

  // 模型分类列表
  getCategoryList() {
    apiCategoryList({}).then((res) => {
      this.categoryList = res.category_list;
    });
  }

  // 修改启用禁用状态
  async shopSwitchIsableEdit(status: any, mx_id: number): Promise<void> {
    await apiChangeIsable({
      sid: this.searchObj.sid,
      mx_id: mx_id,
      isable: status,
    });
    this.getShopLists();
  }

  processInput(input: any) {
        if (typeof input === 'string' && input.includes('{') && input.includes('}')) {
            try {
                const obj = JSON.parse(input);
                return obj.zh_cn;
            } catch (error) {
                return input;
            }
        } else if(typeof input === 'string'){
            return input
        } else {
            return 'Invalid input';
        }
    }

  // 修改上下架状态
  async shopSwitchStatusEdit(status: any, mx_id: number): Promise<void> {
    await apiChangeStatus({
      sid: this.searchObj.sid,
      mx_id: mx_id,
      is_show: status,
    });
    this.getShopLists();
  }

  // 重置搜索
  reset(): void {
    Object.keys(this.searchObj).map((key) => {
      this.$set(this.searchObj, key, "");
    });
    this.getShopLists();
  }

  // 去编辑
  toShopEdit(mx_id: number | any): void {
    this.$router.push({
      name: "shop_model_edit",
      query: {
        mx_id: mx_id as any,
        sid: this.searchObj.sid as any,
        mode: PageMode.EDIT,
      },
      params: {
        pager: this.pager as any,
        searchObj: this.searchObj as any,
      },
    });
  }

  toModelSync(row: any) {
    this.$confirm('此操作将同步模型配置,是否继续?','提示',{
        type: 'warning'
    }).then(()=>{
        return apiAsyncsid({ id: row.id, mx_id: row.mx_id, sid: row.sid })
    }).then(()=>{
        this.$message({
            type: 'success',
            message: '同步成功'
        })
    })
  }
  handleSort(event: any, id: any) {
    apiModelSort({ mx_id: id, sid: this.searchObj.sid, sort: event }).then(
      (res) => {
        this.getShopLists();
      }
    );
  }
  /** S Life Cycle **/
  created() {
    const query: any = this.$route.query;
    const params: any = this.$route.params;
    if (params.pager) {
      this.pager = params.pager;
    }
    if (params.searchObj) {
      (this.searchObj as any) = params.searchObj;
    }
    if (query.id) {
      this.searchObj.sid = query.id;
    }
    this.getShopLists();
    this.getCategoryList();
  }
  /** E Life Cycle **/
}
